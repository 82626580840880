.breadcrumb {
  background-color: white;
  font-size: 14px;
  color: #007bff;
}
.titleHeading {
  cursor: pointer;
}
.titleSubheading {
  color: black;
}
.pagetitle {
  margin-bottom: 8px;
  color: black;
  font-weight: 600;
  font-size: 18px;
}
