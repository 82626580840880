@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");

[data-bs-theme="dark"] {
  --bs-heading-color: #eaeff4 !important;
}

[data-bs-theme="dark"] .tooltip-inner {
  color: #7c8fac !important;
}

[data-bs-theme="dark"] .card {
  --bs-card-title-color: #eaeff4;
  --bs-card-subtitle-color: #7c8fac;
  --bs-card-box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.02) 0px 12px 24px -4px;
}

[data-bs-theme="dark"] .dropdown-menu {
  --bs-dropdown-box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.02) 0px 12px 24px -4px;
}

[data-bs-theme="dark"] .table {
  --bs-table-border-color: #333f55;
}

[data-bs-theme="dark"] .table-hover > tbody > tr:hover > * {
  --bs-table-bg-state: $body-bg-dark;
}

[data-bs-theme="dark"] .table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-bg-type: transparent;
}

[data-bs-theme="dark"] .jsgrid-grid-header,
[data-bs-theme="dark"] .jsgrid-header-row > .jsgrid-header-cell,
[data-bs-theme="dark"] .jsgrid-header-cell,
[data-bs-theme="dark"] .jsgrid-cell {
  background: #202936 !important;
}

[data-bs-theme="dark"] .jsgrid-edit-row > .jsgrid-cell,
[data-bs-theme="dark"] .jsgrid-filter-row > .jsgrid-cell,
[data-bs-theme="dark"] .jsgrid-grid-body,
[data-bs-theme="dark"] .jsgrid-grid-header,
[data-bs-theme="dark"] .jsgrid-header-row > .jsgrid-header-cell,
[data-bs-theme="dark"] .jsgrid-insert-row > .jsgrid-cell {
  border: 1px solid #333f55;
}

[data-bs-theme="dark"] .accordion-item,
[data-bs-theme="dark"] .accordion-item:focus {
  --bs-accordion-btn-color: #eaeff4;
  --bs-accordion-border-color: #333f55;
}

[data-bs-theme="dark"] .accordion-button:not(.collapsed) {
  color: #eaeff4;
}

[data-bs-theme="dark"] .nav-tabs .nav-link,
[data-bs-theme="dark"] .nav-pills .nav-link,
[data-bs-theme="dark"] .breadcrumb-item + .breadcrumb-item::before {
  color: #7c8fac;
}

[data-bs-theme="dark"] .input-group-text {
  background-color: #465670;
  border: var(--bs-table-border-color);
  color: #7c8fac;
}

[data-bs-theme="dark"] .form-check-input {
  border: 1.25px solid #465670;
}

[data-bs-theme="dark"] .bootstrap-switch .bootstrap-switch-label,
[data-bs-theme="dark"] .app-chat-offcanvas {
  background: #202936;
}

[data-bs-theme="dark"] .text-dark,
[data-bs-theme="dark"] .form-label,
[data-bs-theme="dark"] .col-form-label {
  color: #7c8fac !important;
}

[data-bs-theme="dark"] .text-bg-light,
[data-bs-theme="dark"] .bg-light,
[data-bs-theme="dark"] .striped-rows .row:nth-child(odd),
[data-bs-theme="dark"] .bg-hover-light-black:hover {
  background-color: #465670 !important;
  color: var(--bs-card-title-color) !important;
}

[data-bs-theme="dark"] .dropdown-item:hover,
[data-bs-theme="dark"] .dropdown-item:focus {
  background-color: #333f55;
}

[data-bs-theme="dark"] .form-select,
[data-bs-theme="dark"] .form-select:focus,
[data-bs-theme="dark"] .form-control,
[data-bs-theme="dark"] .form-control:focus {
  border-color: #333f55 !important;
  color: #7c8fac;
  background-color: #202936;
}

[data-bs-theme="dark"] .connect-sorting-todo,
[data-bs-theme="dark"] .connect-sorting,
[data-bs-theme="dark"] .app-calendar .fc .fc-button-primary,
[data-bs-theme="dark"]
  .app-calendar
  .fc
  .fc-daygrid-day.fc-day-today
  .fc-daygrid-day-frame,
[data-bs-theme="dark"] .dp-off {
  background: var(--bs-dark-bg-subtle) !important;
}

[data-bs-theme="dark"] .wizard-content .wizard > .steps .step,
[data-bs-theme="dark"]
  .wizard-content
  .wizard
  > .actions
  > ul
  > li
  > a[href="#previous"],
[data-bs-theme="dark"] .dropzone,
[data-bs-theme="dark"] .footable .pagination li a,
[data-bs-theme="dark"] .footable .pagination li.disabled a {
  background-color: #333f55;
  border-color: #333f55;
}

[data-bs-theme="dark"] .wizard-content .wizard.wizard-circle > .steps .step,
[data-bs-theme="dark"] .jsgrid-pager-page a,
[data-bs-theme="dark"] .jsgrid-pager-current-page,
[data-bs-theme="dark"] .jsgrid-pager-nav-button a,
[data-bs-theme="dark"] hr,
[data-bs-theme="dark"] .myadmin-dd-empty .dd-list .dd3-content,
[data-bs-theme="dark"] .myadmin-dd-empty .dd-list .dd3-handle,
[data-bs-theme="dark"] .datepaginator-sm .pagination li a,
[data-bs-theme="dark"] .datepaginator-lg .pagination li a,
[data-bs-theme="dark"] .datepaginator .pagination li a,
[data-bs-theme="dark"] .dp-divider,
[data-bs-theme="dark"] .ql-toolbar.ql-snow,
[data-bs-theme="dark"] .ql-container.ql-snow,
[data-bs-theme="dark"]
  .table-responsive
  .dataTables_wrapper
  .dataTables_filter
  input,
[data-bs-theme="dark"]
  .table-responsive
  .dataTables_wrapper
  .dataTables_length
  select,
[data-bs-theme="dark"]
  .table-responsive
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button,
[data-bs-theme="dark"] .custom-select,
[data-bs-theme="dark"] .tablesaw-swipe .tablesaw-swipe-cellpersist,
[data-bs-theme="dark"] #editable-datatable_wrapper .dataTables_length select,
[data-bs-theme="dark"] #editable-datatable_wrapper .dataTables_filter input,
[data-bs-theme="dark"]
  #editable-datatable_wrapper
  .dataTables_paginate
  .paginate_button {
  border-color: #333f55 !important;
}

[data-bs-theme="dark"]
  .wizard-content
  .wizard
  > .steps
  > ul
  > li.current
  .step {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}

[data-bs-theme="light"][data-color-theme="Blue_Theme"]:root,
[data-bs-theme="dark"][data-color-theme="Blue_Theme"]:root {
  --bs-primary: #5d87ff;
  --bs-primary-rgb: 93, 135, 255;
  --bs-light-primary: rgba(93, 135, 255, 0.1);
  --bs-primary-bg-subtle: rgba(93, 135, 255, 0.1);
  --bs-secondary: #49beff;
  --bs-secondary-rgb: 73, 190, 255;
  --bs-light-secondary: rgba(73, 190, 255, 0.1);
  --bs-secondary-bg-subtle: rgba(73, 190, 255, 0.1);
}

[data-bs-theme="light"][data-color-theme="Blue_Theme"]:root .btn-primary,
[data-bs-theme="dark"][data-color-theme="Blue_Theme"]:root .btn-primary {
  --bs-btn-bg: #5d87ff;
  --bs-btn-border-color: #5d87ff;
  --bs-btn-hover-bg: #4f73d9;
  --bs-btn-hover-border-color: #4f73d9;
}

[data-bs-theme="light"][data-color-theme="Blue_Theme"]:root .btn-secondary,
[data-bs-theme="dark"][data-color-theme="Blue_Theme"]:root .btn-secondary {
  --bs-btn-bg: #49beff;
  --bs-btn-border-color: #49beff;
  --bs-btn-hover-bg: #3ea2d9;
  --bs-btn-hover-border-color: #3a98cc;
}

[data-bs-theme="light"][data-color-theme="Aqua_Theme"]:root,
[data-bs-theme="dark"][data-color-theme="Aqua_Theme"]:root {
  --bs-primary: #0074ba;
  --bs-primary-rgb: 0, 116, 186;
  --bs-light-primary: rgba(0, 116, 186, 0.1);
  --bs-primary-bg-subtle: rgba(0, 116, 186, 0.1);
  --bs-secondary: #47d7bc;
  --bs-secondary-rgb: 71, 215, 188;
  --bs-light-secondary: rgba(71, 215, 188, 0.1);
  --bs-secondary-bg-subtle: rgba(71, 215, 188, 0.1);
}

[data-bs-theme="light"][data-color-theme="Aqua_Theme"]:root .btn-primary,
[data-bs-theme="dark"][data-color-theme="Aqua_Theme"]:root .btn-primary {
  --bs-btn-bg: #0074ba;
  --bs-btn-border-color: #0074ba;
  --bs-btn-hover-bg: #00639e;
  --bs-btn-hover-border-color: #00639e;
}

[data-bs-theme="light"][data-color-theme="Aqua_Theme"]:root .btn-secondary,
[data-bs-theme="dark"][data-color-theme="Aqua_Theme"]:root .btn-secondary {
  --bs-btn-bg: #47d7bc;
  --bs-btn-border-color: #47d7bc;
  --bs-btn-hover-bg: #3cb7a0;
  --bs-btn-hover-border-color: #3cb7a0;
}

[data-bs-theme="light"][data-color-theme="Purple_Theme"]:root,
[data-bs-theme="dark"][data-color-theme="Purple_Theme"]:root {
  --bs-primary: #763ebd;
  --bs-primary-rgb: 118, 62, 189;
  --bs-light-primary: rgba(118, 62, 189, 0.1);
  --bs-primary-bg-subtle: rgba(118, 62, 189, 0.1);
  --bs-secondary: #95cfd5;
  --bs-secondary-rgb: 149, 207, 213;
  --bs-light-secondary: rgba(149, 207, 213, 0.1);
  --bs-secondary-bg-subtle: rgba(149, 207, 213, 0.1);
}

[data-bs-theme="light"][data-color-theme="Purple_Theme"]:root .btn-primary,
[data-bs-theme="dark"][data-color-theme="Purple_Theme"]:root .btn-primary {
  --bs-btn-bg: #763ebd;
  --bs-btn-border-color: #763ebd;
  --bs-btn-hover-bg: #6435a1;
  --bs-btn-hover-border-color: #6435a1;
}

[data-bs-theme="light"][data-color-theme="Purple_Theme"]:root .btn-secondary,
[data-bs-theme="dark"][data-color-theme="Purple_Theme"]:root .btn-secondary {
  --bs-btn-bg: #95cfd5;
  --bs-btn-border-color: #95cfd5;
  --bs-btn-hover-bg: #7fb0b5;
  --bs-btn-hover-border-color: #7fb0b5;
}

[data-bs-theme="light"][data-color-theme="Green_Theme"]:root,
[data-bs-theme="dark"][data-color-theme="Green_Theme"]:root {
  --bs-primary: #0a7ea4;
  --bs-primary-rgb: 10, 126, 164;
  --bs-light-primary: rgba(10, 126, 164, 0.1);
  --bs-primary-bg-subtle: rgba(10, 126, 164, 0.1);
  --bs-secondary: #ccda4e;
  --bs-secondary-rgb: 204, 218, 78;
  --bs-light-secondary: rgba(204, 218, 78, 0.1);
  --bs-secondary-bg-subtle: rgba(204, 218, 78, 0.1);
}

[data-bs-theme="light"][data-color-theme="Green_Theme"]:root .btn-primary,
[data-bs-theme="dark"][data-color-theme="Green_Theme"]:root .btn-primary {
  --bs-btn-bg: #0a7ea4;
  --bs-btn-border-color: #0a7ea4;
  --bs-btn-hover-bg: #096b8b;
  --bs-btn-hover-border-color: #096b8b;
}

[data-bs-theme="light"][data-color-theme="Green_Theme"]:root .btn-secondary,
[data-bs-theme="dark"][data-color-theme="Green_Theme"]:root .btn-secondary {
  --bs-btn-bg: #ccda4e;
  --bs-btn-border-color: #ccda4e;
  --bs-btn-hover-bg: #d4e069;
  --bs-btn-hover-border-color: #d4e069;
}

[data-bs-theme="light"][data-color-theme="Cyan_Theme"]:root,
[data-bs-theme="dark"][data-color-theme="Cyan_Theme"]:root {
  --bs-primary: #01c0c8;
  --bs-primary-rgb: 1, 192, 200;
  --bs-light-primary: rgba(1, 192, 200, 0.1);
  --bs-primary-bg-subtle: rgba(1, 192, 200, 0.1);
  --bs-secondary: #fb9678;
  --bs-secondary-rgb: 251, 150, 120;
  --bs-light-secondary: rgba(251, 150, 120, 0.1);
  --bs-secondary-bg-subtle: rgba(251, 150, 120, 0.1);
}

[data-bs-theme="light"][data-color-theme="Cyan_Theme"]:root .btn-primary,
[data-bs-theme="dark"][data-color-theme="Cyan_Theme"]:root .btn-primary {
  --bs-btn-bg: #01c0c8;
  --bs-btn-border-color: #01c0c8;
  --bs-btn-hover-bg: #01a3aa;
  --bs-btn-hover-border-color: #01a3aa;
}

[data-bs-theme="light"][data-color-theme="Cyan_Theme"]:root .btn-secondary,
[data-bs-theme="dark"][data-color-theme="Cyan_Theme"]:root .btn-secondary {
  --bs-btn-bg: #fb9678;
  --bs-btn-border-color: #fb9678;
  --bs-btn-hover-bg: #d58066;
  --bs-btn-hover-border-color: #d58066;
}

[data-bs-theme="light"][data-color-theme="Orange_Theme"]:root,
[data-bs-theme="dark"][data-color-theme="Orange_Theme"]:root {
  --bs-primary: #fa896b;
  --bs-primary-rgb: 250, 137, 107;
  --bs-light-primary: rgba(250, 137, 107, 0.1);
  --bs-primary-bg-subtle: rgba(250, 137, 107, 0.1);
  --bs-secondary: #0074ba;
  --bs-secondary-rgb: 0, 116, 186;
  --bs-light-secondary: rgba(0, 116, 186, 0.1);
  --bs-secondary-bg-subtle: rgba(0, 116, 186, 0.1);
}

[data-bs-theme="light"][data-color-theme="Orange_Theme"]:root .btn-primary,
[data-bs-theme="dark"][data-color-theme="Orange_Theme"]:root .btn-primary {
  --bs-btn-bg: #fa896b;
  --bs-btn-border-color: #fa896b;
  --bs-btn-hover-bg: #d5745b;
  --bs-btn-hover-border-color: #d5745b;
}

[data-bs-theme="light"][data-color-theme="Orange_Theme"]:root .btn-secondary,
[data-bs-theme="dark"][data-color-theme="Orange_Theme"]:root .btn-secondary {
  --bs-btn-bg: #0074ba;
  --bs-btn-border-color: #0074ba;
  --bs-btn-hover-bg: #00639e;
  --bs-btn-hover-border-color: #00639e;
}

/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme="light"] {
  --bs-blue: #5d87ff;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #fa896b;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffae1f;
  --bs-green: #13deb9;
  --bs-teal: #20c997;
  --bs-cyan: #539bff;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #2a3547;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f6f9fc;
  --bs-gray-200: #eaeff4;
  --bs-gray-300: #dfe5ef;
  --bs-gray-400: #7c8fac;
  --bs-gray-500: #5a6a85;
  --bs-gray-600: #2a3547;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #5d87ff;
  --bs-secondary: #49beff;
  --bs-success: #13deb9;
  --bs-info: #539bff;
  --bs-warning: #ffae1f;
  --bs-danger: #fa896b;
  --bs-light: #f6f9fc;
  --bs-dark: #2a3547;
  --bs-muted: #5a6a85;
  --bs-indigo: #6610f2;
  --bs-light-indigo: #ebf3fe;
  --bs-primary-rgb: 93, 135, 255;
  --bs-secondary-rgb: 73, 190, 255;
  --bs-success-rgb: 19, 222, 185;
  --bs-info-rgb: 83, 155, 255;
  --bs-warning-rgb: 255, 174, 31;
  --bs-danger-rgb: 250, 137, 107;
  --bs-light-rgb: 246, 249, 252;
  --bs-dark-rgb: 42, 53, 71;
  --bs-muted-rgb: 90, 106, 133;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-light-indigo-rgb: 235, 243, 254;
  --bs-primary-text-emphasis: shade-color(#5d87ff, 60%);
  --bs-secondary-text-emphasis: shade-color(#49beff, 60%);
  --bs-success-text-emphasis: shade-color(#13deb9, 60%);
  --bs-info-text-emphasis: shade-color(#539bff, 60%);
  --bs-warning-text-emphasis: shade-color(#ffae1f, 60%);
  --bs-danger-text-emphasis: shade-color(#fa896b, 60%);
  --bs-light-text-emphasis: #f6f9fc;
  --bs-dark-text-emphasis: #2a3547;
  --bs-primary-bg-subtle: #ecf2ff;
  --bs-secondary-bg-subtle: #e8f7ff;
  --bs-success-bg-subtle: #e6fffa;
  --bs-info-bg-subtle: #ebf3fe;
  --bs-warning-bg-subtle: #fef5e5;
  --bs-danger-bg-subtle: #fbf2ef;
  --bs-light-bg-subtle: #f6f9fc;
  --bs-dark-bg-subtle: #2a3547;
  --bs-primary-border-subtle: #becfff;
  --bs-secondary-border-subtle: #b6e5ff;
  --bs-success-border-subtle: #a1f2e3;
  --bs-info-border-subtle: #bad7ff;
  --bs-warning-border-subtle: #ffdfa5;
  --bs-danger-border-subtle: #fdd0c4;
  --bs-light-border-subtle: #eaeff4;
  --bs-dark-border-subtle: #5a6a85;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Plus Jakarta Sans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #5a6a85;
  --bs-body-color-rgb: 90, 106, 133;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(90, 106, 133, 0.75);
  --bs-secondary-color-rgb: 90, 106, 133;
  --bs-secondary-bg: #eaeff4;
  --bs-secondary-bg-rgb: 234, 239, 244;
  --bs-tertiary-color: rgba(90, 106, 133, 0.5);
  --bs-tertiary-color-rgb: 90, 106, 133;
  --bs-tertiary-bg: #f6f9fc;
  --bs-tertiary-bg-rgb: 246, 249, 252;
  --bs-heading-color: #2a3547;
  --bs-link-color: #2a3547;
  --bs-link-color-rgb: 42, 53, 71;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #5d87ff;
  --bs-link-hover-color-rgb: 93, 135, 255;
  --bs-code-color: #d63384;
  --bs-highlight-color: #5a6a85;
  --bs-highlight-bg: #ffefd2;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #ebf1f6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 7px;
  --bs-border-radius-sm: 5px;
  --bs-border-radius-lg: 15px;
  --bs-border-radius-xl: 12px;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.12);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: unset;
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(93, 135, 255, 0.25);
  --bs-form-valid-color: #13deb9;
  --bs-form-valid-border-color: #13deb9;
  --bs-form-invalid-color: #fa896b;
  --bs-form-invalid-border-color: #fa896b;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #7c8fac;
  --bs-body-color-rgb: 124, 143, 172;
  --bs-body-bg: #202936;
  --bs-body-bg-rgb: 32, 41, 54;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: #7c8fac;
  --bs-secondary-color-rgb: 124, 143, 172;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(124, 143, 172, 0.5);
  --bs-tertiary-color-rgb: 124, 143, 172;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #9eb7ff;
  --bs-secondary-text-emphasis: #92d8ff;
  --bs-success-text-emphasis: #71ebd5;
  --bs-info-text-emphasis: #98c3ff;
  --bs-warning-text-emphasis: #ffce79;
  --bs-danger-text-emphasis: #fcb8a6;
  --bs-light-text-emphasis: #f6f9fc;
  --bs-dark-text-emphasis: #dfe5ef;
  --bs-primary-bg-subtle: #253662;
  --bs-secondary-bg-subtle: #1c455d;
  --bs-success-bg-subtle: #1b3c48;
  --bs-info-bg-subtle: #223662;
  --bs-warning-bg-subtle: #4d3a2a;
  --bs-danger-bg-subtle: #4b313d;
  --bs-light-bg-subtle: #465670;
  --bs-dark-bg-subtle: #333f55;
  --bs-primary-border-subtle: #385199;
  --bs-secondary-border-subtle: #2c7299;
  --bs-success-border-subtle: #0b856f;
  --bs-info-border-subtle: #325d99;
  --bs-warning-border-subtle: #996813;
  --bs-danger-border-subtle: #965240;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #7c8fac;
  --bs-link-hover-color: #5d87ff;
  --bs-link-color-rgb: 124, 143, 172;
  --bs-link-hover-color-rgb: 93, 135, 255;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #7c8fac;
  --bs-highlight-bg: #66460c;
  --bs-border-color: #333f55;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #71ebd5;
  --bs-form-valid-border-color: #71ebd5;
  --bs-form-invalid-color: #fcb8a6;
  --bs-form-invalid-border-color: #fcb8a6;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: #f1f4fa;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid #ebf1f6;
  opacity: 1;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1,
.h1 {
  font-size: calc(1.34375rem + 1.125vw);
}

/* @media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.1875rem;
  }
} */

h2,
.h2 {
  font-size: calc(1.3rem + 0.6vw);
}

/* @media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 1.75rem;
  }
} */

h3,
.h3 {
  font-size: calc(1.278125rem + 0.3375vw);
}

/* @media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.53125rem;
  }
} */

h4,
.h4 {
  font-size: calc(1.25625rem + 0.075vw);
}

/* @media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.3125rem;
  }
} */

h5,
.h5 {
  font-size: 1.09375rem;
}

h6,
.h6 {
  font-size: 0.875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

html[data-boxed-layout="boxed"] .container-fluid,
html[data-boxed-layout="boxed"] .container-sm,
html[data-boxed-layout="boxed"] .container-md,
html[data-boxed-layout="boxed"] .container-lg,
html[data-boxed-layout="boxed"] .container-xl,
html[data-boxed-layout="boxed"] .container-xxl {
  max-width: 1225px;
  margin: 0 auto;
}

img {
  max-width: 100%;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 5px;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 16px;
  padding-bottom: 16px;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  font-weight: 600;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not(
    [type="week"]
  ):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

/* @media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
} */
legend + * {
  clear: left;
}

ul {
  list-style: none;
}

ul {
  list-style: none;
  padding: 0;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

/* @media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
} */

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

/* @media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
} */

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

/* @media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
} */

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

/* @media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
} */

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

/* @media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
} */

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

/* @media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
} */
.page-wrapper {
  margin-left: 270px;
}

.with-horizontal {
  display: none;
}

.with-vertical {
  display: block;
}

/* card 1 */
.card-body {
  background: #fff;
  color: #222;
  border-radius: 7px;
  word-wrap: break-word;
}

.w-auto {
  width: auto !important;
}

.areaheading {
  margin-right: 10px;
  margin-top: 5px;
}

.viewBtn {
  white-space: nowrap;
  margin-left: 2.5rem;
}

.form-select,
.viewBtn,
.searchInput,
.ticketBtn {
  padding: 8px 38px 8px 16px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #5a6a85;
  background-color: transparent;
  border: var(--bs-border-width) solid #dfe5ef;
  border-radius: 7px;
}

.searchInput {
  width: 100%;
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px 12px;
  box-shadow: unset;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.viewBtn img {
  margin-right: 15px;
}

select {
  word-wrap: normal;
}

.card-title {
  font-size: 22px;
  color: #222;
  font-weight: 700;
}

.d-sm-flex {
  display: flex !important;
}

/* card 1 */
/* card 2*/
.card,
device_box {
  padding: 17px;
  background: #fff;
  color: #222;
  border-radius: 7px;
  word-wrap: break-word;
}

.device_box {
  padding: 15px;
  margin-bottom: 10px;
}

.device_box span {
}

.act_box.w-auto {
  background: #ffc1a4;
  padding: 10px;
  border-radius: 15px;
  line-height: 1.4;
  text-align: center;
}

.ac1 {
  color: #222;

  line-height: 1.4;
  margin-bottom: 0px;
  font-size: 14px;
}

.ac2 {
  color: #222;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 14px;
}

.col-box-6 p {
  background: #ffe6db;
  font-size: 14px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  color: #222;
  border-radius: 15px;
  font-weight: 500;
  line-height: 1.4;
}

.col-box-6 span {
  font-weight: bold;
}

.col-box1 p,
.col-boxt1 p {
  background-color: #6c3bff4d;
}

.col-box2 p,
.col-boxt2 p {
  background-color: #ffc1a4;
}

.col-box3 p,
.col-boxt3 p {
  background-color: #cce9ff;
}

.col-box4 p {
  background-color: #d1f7ea;
}

.col-box1 span,
.col-box2 span,
.col-box3 span,
.col-box4 span {
  font-size: 14px;
}

.select-box-6 p {
  color: #222;
  margin-bottom: 5px;
  font-size: 13px;
  margin-top: 6px;
}

.map2 {
  margin-top: 15px;
}

.tm1,
.tm3 {
  margin-bottom: 5px;
  margin-top: 15px;
  color: #222;
  font-size: 14px;
}

.tm2,
.tm4 {
  margin-bottom: 15px;
  color: #222;
  font-size: 12px;
}

.tm2 span {
  margin-left: 15px;
  color: #b0adad;
}

.tm3 {
  font-size: 2rem;
  font-weight: 100;
}

.tm3,
.tm4 {
  font-family: Helvetica, Arial, sans-serif;
}

.mb-9 {
  margin-bottom: 20px !important;
}

/* card 2 */

/* card 3 */
.card.speed_sc {
  margin-bottom: 15px;
}

.fs-3 {
  font-size: 13px;
  margin-bottom: 0px;
}

.new_cl {
  background: #d3c4ff !important;
}

.mb_new1 {
  display: flex;
}

.speed_sc .badge.bg-primary-subtle.text-primary.fw-semibold.fs-3 {
  background: no-repeat !important;
  color: #222 !important;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.progress,
.progress-stacked {
  --bs-progress-height: 7px;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: #fff;
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #5d87ff;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  box-shadow: var(--bs-progress-box-shadow);
}

.progress_cl1 {
  background: #5d3aff !important;
}

.w-50 {
  width: 50% !important;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

.mb_new1 {
  margin-bottom: 10px;
}

.progress_cl2 {
  background: #db5aee !important;
}

.progress_cl3 {
  background: #1ad598 !important;
}

.progress_cl4 {
  background: #f9b959 !important;
}

.list_ul li {
  width: 19%;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  margin-bottom: 0px !important;
}

.list_ul ul {
  margin-bottom: 0px;
  list-style: none;
  padding: 0;
}

.new_c_1 {
  background: #e5f5f5 !important;
  margin-bottom: 15px;
  margin-top: 15px;
}

/* card 3 */

.backBtn {
  float: right;
  margin-top: 12px;
  position: relative;
}

.backTicketBtn {
  float: left;
  margin-bottom: 20px;
  margin-top: -45px;
}

.backBtn,
.backTicketBtn,
.detailsBtn,
.ticketactive,
.ticketclosed {
  border: 1px solid #dfe5ef;
  color: #000;
  background: #fff;
  padding: 9px 26px;
  border-radius: 14px;
  font-size: 14px;
}

.tableheading {
  font-weight: 700;
  font-size: 16px;
  margin-left: 4px;
  margin-top: 30px;
}

.ticketBtn {
  color: #fff;
  float: right;
  background: #32387d;
  margin-top: 18px;
}

.ticketMapBtn {
  padding: 8px 14px 8px 29px;
  margin-top: 0px;
}

.emptyTable {
  text-align: center;
  font-size: 22px;
  margin-top: 10rem;
  font-weight: bold;
}

.tableRowActive {
  background-color: #7992ec !important;
}

.tableList {
  overflow-y: auto;
  scrollbar-width: thin;
  /* margin-top: 15px; */
}

.tableList thead th {
  position: sticky;
  top: 0;
  font-weight: 700;
}

#tableListId {
  font-family: inherit;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

#tableListId td,
#tableListId th {
  border: 1px solid #dfe5ef;
  padding: 8px;
  text-align: center;
  font-size: 14px;
}

.ticketactive {
  background: #1a43d533;
  color: #32387d;
  cursor: default !important;
}

.ticketclosed {
  color: #005d3f;
  background: #1ad59833;
  cursor: default !important;
}

.table-no-border td,
.table-no-border th {
  border: none !important;
}

#tableListId tr:nth-child(odd) {
  background-color: white;
}

#tableListId th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #e2e8fe;
  color: #000;
}

#tableListId td {
  font-weight: 500;
}

.tab-body-content {
  border: 1px solid #dfe5ef;
  padding: 14px;
}

.tab-body-content h5 {
  text-align: left;
  margin-left: 4px;
  margin-top: 10px;
  font-size: 18px;
}

.tab-heading {
  width: 25%;
}

.tab-heading a {
  color: #000;
}

.tab-heading .active {
  background: #b5b2f0;
  color: #000;
}

.main-content {
  display: flex;
}

.content-1 {
  width: 75%;
}

.content-2 {
  width: 25%;
  background: #d8d7f8;
  border-radius: 14px;
  padding: 8px;
  margin-top: 8px;
  margin-left: 10px;
  text-align: left;
  margin-bottom: 5px;
}

.ticketSummary {
  margin-left: 10px;
}

.ticketSummary p {
  font-weight: 700;
}

.ticketSummary span {
  font-weight: 400;
}

.tab-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  text-align: left;
}

.tab-list li {
  font-weight: 700;
  padding: 8px;
}

.tab-list li span,
.tab-description span {
  font-weight: 500;
}

.tab-description {
  text-align: left;
  background: #e8fff5;
  padding: 14px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  margin-top: 5rem;
}

.tab-description span {
  margin-top: 6px;
}

.graphContainer {
  overflow-y: scroll;
  scrollbar-width: thin;
}

.highcharts-title {
  font-size: 2rem !important;
  position: relative;
  white-space: nowrap;
}

.mt-15 {
  margin-top: 15px;
}

.mt-5 {
  margin-top: 5px;
}

.padding-0 {
  padding-left: 0;
}

/*Loader CSS*/
.loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

#loader-1 span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #3498db;
  margin: 35px 5px;
}

#loader-1 span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}

#loader-1 span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}

#loader-1 span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}

@keyframes bounce {
  0%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

#close-icon {
  display: none;
}

.mobile-nav {
  display: none;
}

.vw1 {
  height: 100%;
  width: 100%;
}

.card.wscreen {
  margin-bottom: 15px;
}

/*Media Query*/
@media screen and (min-width: 1366px) and (max-width: 1366px) {
  .th1 {
    height: 164px !important;
  }

  .th2 {
    height: 228px !important;
  }

  .th3 {
    height: 185px !important;
  }

  .dev_dt1 {
    height: 340px !important;
  }

  .dev_dt2 {
    height: 338px !important;
  }

  .dev_dt3 {
    height: 420px !important;
  }

  .dev_dt4 {
    height: 504px !important;
  }

  .tableList {
    height: 364px !important;
  }

  .tablesList {
    height: 332px !important;
  }

  .tableListHeight {
    height: 384px !important;
  }

  .tableHeight {
    height: 424px !important;
  }

  .radiobtns {
    width: 100%;
  }

  .col-box1,
  .col-box2 {
    width: 110%;
  }

  .map2 {
    margin-top: 0;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1440px) {
  .th1 {
    height: 184px !important;
  }

  .th2 {
    height: 250px !important;
  }

  .th3 {
    height: 204px !important;
  }

  .dev_dt1 {
    height: 388px !important;
  }

  .dev_dt2 {
    height: 390px !important;
  }

  .dev_dt3 {
    height: 456px !important;
  }

  .dev_dt4 {
    height: 494px !important;
  }

  .tableList {
    height: 394px !important;
  }

  .tablesList {
    height: 382px !important;
  }

  .tableListHeight {
    height: 414px !important;
  }

  .tableHeight {
    height: 462px !important;
  }
}

@media screen and (max-width: 1920px) {
  .wscreen {
    width: 100% !important;
  }

  .th1 {
    height: 230px;
  }

  .th2 {
    height: 290px;
  }

  .th3 {
    height: 248px;
  }

  .dev_dt1 {
    height: 436px;
  }

  .dev_dt2 {
    height: 434px;
  }

  .dev_dt3 {
    height: 496px;
  }

  .dev_dt4 {
    height: 494px;
  }

  .tableList {
    height: 440px;
  }

  .tablesList {
    height: 424px;
  }

  .tableListHeight {
    height: 460px;
  }

  .tableHeight {
    height: 500px;
  }

  .radiobtns {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  #tableListId {
    margin-top: 0px;
  }

  .viewBtn {
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 13px;
  }

  .ticketBtn.ticketMapBtn {
    z-index: 99;
    position: relative;
  }

  .d-sm-flex.d-block.align-items-center.justify-content-between.mb-9 {
    display: inline !important;
  }

  .card-title {
    margin-bottom: 11px;
  }

  .form-select.w-auto {
    margin-bottom: 15px;
  }

  .padding-0 {
    padding-left: 15px;
  }

  .radiobtns {
    width: 100%;
    margin-top: 11px;
  }

  .page-wrapper {
    margin-left: 0px;
  }

  .left-sidebar {
    width: 100%;
  }

  .topbar {
    width: 100% !important;
  }

  #close-icon {
    display: block;
  }

  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
  }

  .brand_menu {
    float: right;
    position: relative;
    bottom: 5px;
    right: 14px;
  }

  .dark-logo {
    max-width: 64%;
    margin-top: 14px;
    margin-bottom: 15px;
  }

  .welcome_sms {
    font-size: 16px;
  }

  .card.speed_sc {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .mobile-nav {
    height: 70px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    width: 100%;
  }

  .form-select.w-auto {
    width: 100% !important;
    font-size: 13px;
    background-position: right 8px center;
    background-size: 8px 14px;
    padding: 7px 20px !important;
  }
}
