.left-sidebar {
  width: 270px;
  border-right: 1px solid var(--bs-border-color);
  flex-shrink: 0;
  background: var(--bs-body-bg);
  z-index: 99;
  transition: 0.2s ease-in;
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
}

.left-sidebar .sidebartoggler {
  color: var(--bs-dark-text-emphasis);
}

.left-sidebar .scroll-sidebar {
  overflow-y: auto;
  padding: 0 24px;
  height: calc(100vh - 275px);
  border-radius: 7px;
}

.left-sidebar .scroll-sidebar .simplebar-track.simplebar-horizontal {
  visibility: hidden !important;
}

.simplebar-scrollbar:before {
  background: rgba(0, 0, 0, 0.5) !important;
}

.sidebar-nav ul .sidebar-item .sidebar-link {
  display: flex;
  font-size: 14px;
  white-space: nowrap;
  align-items: center;
  line-height: 25px;
  position: relative;
  margin: 0px 0px 2px;
  padding: 10px;
  border-radius: 7px;
  color: #222;
  gap: 15px;
  text-decoration: none;
  font-weight: 400;
}

.sidebar-nav ul .sidebar-item .sidebar-link span:first-child {
  display: flex;
}

.sidebar-nav ul .sidebar-item .sidebar-link .ti {
  flex-shrink: 0;
  font-size: 21px;
}

.sidebar-link.active {
  background: rgb(50, 56, 125);
  color: #fff !important;
}

.sidebar-nav ul .sidebar-item .sidebar-link:hover {
  /* background: rgb(50, 56, 125); */
  /* color: #fff; */
}

.sidebar-nav ul .sidebar-item .sidebar-link:hover.has-arrow::after {
  border-color: var(--bs-primary);
}

.sidebar-nav ul .sidebar-item .sidebar-link.active:hover.has-arrow::after {
  border-color: var(--bs-white);
}

.sidebar-nav ul .sidebar-item .link-disabled {
  opacity: 0.38;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item {
  transition: all 0.4s ease-in-out;
  border-bottom: 0;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link:hover {
  background-color: transparent;
  color: var(--bs-primary);
}

.sidebar-nav
  ul
  .sidebar-item
  .first-level
  .sidebar-item
  .sidebar-link.active.has-arrow::after {
  border-color: var(--bs-primary);
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item > .sidebar-link {
  padding: 8px 10px;
  border-radius: 7px;
  font-size: 14px;
  gap: 23px;
}

.sidebar-nav
  ul
  .sidebar-item
  .first-level
  .sidebar-item
  > .sidebar-link
  .sidebar-icon {
  flex-shrink: 0;
  margin-left: 12px;
  margin-right: 35px;
  width: 14px;
  height: 14px;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item:last-child {
  margin-bottom: 16px;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-link .ti {
  font-size: 7px;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link.active {
  background-color: transparent !important;
  color: var(--bs-primary) !important;
}

.sidebar-nav ul .sidebar-item .two-level .sidebar-item .sidebar-link {
  padding: 8px 10px 8px 45px;
}

.sidebar-nav ul .sidebar-item .three-level .sidebar-item .sidebar-link {
  padding: 8px 10px 8px 60px;
}

/* .sidebar-nav ul .sidebar-item.selected > .sidebar-link.active, .sidebar-nav ul .sidebar-item.selected > .sidebar-link,
  .sidebar-nav ul .sidebar-item > .sidebar-link.active {
    background-color: var(--bs-primary);
    color: var(--bs-white);
   }*/
.sidebar-nav ul .sidebar-item.selected .sidebar-link.has-arrow::after {
  border-color: var(--bs-white);
}

.sidebar-nav .sidebar-list .sidebar-list-item {
  padding: 8px 0;
}

.nav-small-cap {
  margin-top: 24px;
  color: var(--bs-link-color);
  font-size: 12px;
  font-weight: 700;
  padding: 3px 0;
  line-height: 26px;
  text-transform: uppercase;
}

.nav-small-cap .nav-small-cap-icon {
  display: none;
}

.collapse.in {
  display: block;
}

.hstack_cd1 {
  padding-left: 13px;
}

.cp1 {
  margin-bottom: 5px;
  font-size: 12px;
  color: #222;
  margin-top: 30px;
}

.cp2 a {
  padding-bottom: 5px;
  font-size: 14px;
  color: #222;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.brand-logo {
  min-height: 70px;
  padding: 0 24px;
  margin-bottom: 20px;
}

.dark-logo {
  max-width: 88%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.btn_log_out {
  border-radius: 15px;
  border: 1px solid #32387d;
  color: black;
  background: #fff;
  padding: 12px 35px;
  border-radius: 14px;
  font-size: 15px;
  position: relative;
  top: 10px;
  width: 90%;
}

.childItems {
  margin-left: 20px !important;
}
